/*
 |-----------------------------------------------------------------------------
 | components/atoms/Attribution/Attribution.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

// import { ray } from 'node-ray/web';

import * as IAttribution from './types';

const Attribution: FC<IAttribution.IProps> = ({
	darkMode,
	name,
	url,
	utilities = '',
}) => {
	// ray('Debug atom Attribution:', {
	// 	darkMode: darkMode,
	// 	name: name,
	// 	url: url,
	// 	utilities: utilities,
	// }).red();

	return (
		<p
			className={`
				font-normal
				text-base
				sm:text-sm
				${darkMode ? `text-neutral-100` : `text-neutral-900`}
				${utilities}
			`}
			data-testid="attribution"
		>
			Website crafted by{' '}
			<a
				className={`
					link
					${darkMode ? `after:bg-neutral-100` : `after:bg-neutral-900`}
				`}
				href={url}
				rel="external noopener noreferrer"
				target="_blank"
				title={`Visit ${name}`}
			>
				{name}
			</a>
		</p>
	);
};

export default Attribution;
