/*
 |-----------------------------------------------------------------------------
 | components/organisms/Footer/Footer.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { get, map } from 'lodash';
import dynamic from 'next/dynamic';
// import { ray } from 'node-ray/web';

import Attribution from '@/atoms/Attribution';
import Container from '@/layouts/Container';
import Copyright from '@/atoms/Copyright';
import FooterMenu from '@/molecules/FooterMenu';
import { country } from '@/utils/helpers';
import { getMenu } from '@/utils/menus';
import { menusSelector } from '@/features/menus';
import { organisationSelector } from '@/features/organisation';
import { useAppSelector } from '@/app/hooks';

const Address = dynamic(() => import('@/molecules/Address'));
const ContactPoint = dynamic(() => import('@/molecules/ContactPoint'));
const Registration = dynamic(() => import('@/atoms/Registration'));
const SocialMedia = dynamic(() => import('@/molecules/SocialMedia'));

const Footer = ({ darkMode }) => {
	// ray('Debug organism Footer:', {
	// 	darkMode: darkMode,
	// }).purple();

	const menus = useAppSelector(menusSelector);
	const organisation = useAppSelector(organisationSelector);

	const menu = getMenu('footer', menus);

	return (
		<footer
			className={`
				py-16
				space-y-4
				${darkMode ? 'bg-neutral-900' : 'bg-neutral-100'}
			`}
			itemScope
			itemType="http://schema.org/Footer"
		>
			<Container utilities={`flex justify-between`}>
				{menu && (
					<FooterMenu
						darkMode={darkMode}
						items={get(menu, 'items')}
						label={get(menu, 'label')}
					/>
				)}

				{get(organisation, 'addresses') &&
					map(get(organisation, 'addresses'), (address, index) => (
						<Address
							key={index}
							country={country(get(address, 'country'))}
							locality={get(address, 'locality')}
							postcode={get(address, 'postcode')}
							region={get(address, 'region')}
							street={get(address, 'street')}
						/>
					))}

				{get(organisation, 'contactPoints') &&
					map(
						get(organisation, 'contactPoints'),
						(contactPoint, index) => (
							<ContactPoint
								key={index}
								email={get(contactPoint, 'email')}
								hideLabels
								phone={get(contactPoint, 'phone')}
							/>
						)
					)}

				{get(organisation, 'platforms') && (
					<SocialMedia platforms={get(organisation, 'platforms')} />
				)}
			</Container>
			<Container utilities={`flex items-end justify-between`}>
				{(get(organisation, 'name') ||
					get(organisation, 'registrations')) && (
					<div>
						{get(organisation, 'registrations') && (
							<Registration
								coNo={get(organisation, [
									'registrations',
									'coNo',
								])}
								darkMode={darkMode}
								vatNo={get(organisation, [
									'registrations',
									'vatNo',
								])}
							/>
						)}

						{get(organisation, 'name') && (
							<Copyright
								darkMode={darkMode}
								name={get(organisation, 'name')}
								year={2022}
							/>
						)}
					</div>
				)}

				<Attribution
					darkMode={darkMode}
					name="Marka"
					url="https://www.markastudios.co.uk/"
				/>
			</Container>
		</footer>
	);
};

export default Footer;
