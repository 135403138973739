/*
 |-----------------------------------------------------------------------------
 | components/molecules/FooterMenu/FooterMenu.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React from 'react';

import { get, map } from 'lodash';
import Link from 'next/link';
import dynamic from 'next/dynamic';
// import { ray } from 'node-ray/web';

import * as IFooterMenu from './types';

const Icon = dynamic(() => import('@/atoms/Icon'));

const FooterMenu: React.FC<IFooterMenu.IProps> = ({
	darkMode,
	items,
	label,
	utilities = '',
}) => {
	// ray('Debug molecule FooterMenu:', {
	// 	darkMode: darkMode,
	// 	items: items,
	// 	label: label,
	// 	utilities: utilities,
	// }).blue();

	const classNames = `
		group
		link
		${darkMode ? `after:bg-neutral-100` : `after:bg-neutral-900`}
	`;

	const externalLink = (label, url, utilities) => {
		return (
			<a
				className={utilities}
				href={url}
				rel="external noopener noreferrer"
				target="_blank"
			>
				{label}

				<Icon
					fill="fill-current"
					id="r-link"
					size="xs"
					utilities={`
						external
						inline-block
						ml-2
					`}
				/>
			</a>
		);
	};

	const internalLink = (label, path, utilities) => {
		return (
			<Link href={path || '#'} passHref>
				<a className={utilities} href="jsx-a11y">
					{label}
				</a>
			</Link>
		);
	};

	return (
		<ul
			className={`text-[0] ${utilities}`}
			aria-label={label}
			data-testid="footerMenu"
		>
			{map(items, (item, index) => (
				<li
					key={index}
					className={`
						font-normal
						inline-block
						mr-4
						text-base
						sm:text-sm
						${darkMode ? `text-neutral-100` : `text-neutral-900`}
					`}
				>
					{get(item, 'url')
						? externalLink(
								get(item, 'label'),
								get(item, 'url'),
								classNames
						  )
						: internalLink(
								get(item, 'label'),
								get(item, 'page'),
								classNames
						  )}
				</li>
			))}
		</ul>
	);
};

export default FooterMenu;
