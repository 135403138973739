/*
 |-----------------------------------------------------------------------------
 | components/atoms/Copyright/Copyright.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

// import { ray } from 'node-ray/web';

import { now } from '@/utils/helpers';

import * as ICopyright from './types';

const Copyright: FC<ICopyright.IProps> = ({
	darkMode,
	name,
	utilities = '',
	year,
}) => {
	// ray('Debug atom Copyright:', {
	// 	darkMode: darkMode,
	// 	name: name,
	// 	utilities: utilities,
	// 	year: year,
	// }).red();

	return (
		<p
			className={`
				font-normal;
				text-base
				sm:text-sm
				${darkMode ? `text-neutral-100` : `text-neutral-900`}
				${utilities}
			`}
			data-testid="copyright"
		>
			&copy; {now(year)} {name}. All rights reserved.
		</p>
	);
};

export default Copyright;
